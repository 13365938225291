<template>
  <div class="template-con">
    <h2>成绩导入模板</h2>
    <div class="line-num">
      标题行所在行(从1开始)：
      <span class="usable" v-if="!edit">{{scoreTitleRowIdx}}</span>
      <span v-else  class="usable">
        <a-input  v-model="scoreTitleRowIdx"></a-input>
      </span> 
    </div>
    <div class="line-num" style="border-top:none;margin-top:0;padding-top:0;">
      考试日期是否启用第一个单元格：
      <span class="usable" v-if="!edit">{{scoreExamDayUseFirstCell?'是':'否'}}</span>
      <span v-else  class="usable">
        <a-switch checked-children="是" un-checked-children="否" v-model="scoreExamDayUseFirstCell" />
      </span> 
    </div>
    <div class="flex" >
      <label for="" class="info-title">序号</label>
      <span class="usable" >标题</span>
      <span class="usable">是否可用</span>
      <span class="usable" style="width:200px">标题参考</span>
    </div> 
    <div class="flex" v-for="item in keyList" :key="item.colKeyId">
      <label for="" v-if="!edit" class="info-title">{{item.colKeyId}}</label>
      <span v-else class="info-title">
        <a-input v-model="item.colKeyId"></a-input>
      </span>
      <span class="usable" v-if="!edit">{{item.colTitle}}</span>
      <span v-else  class="usable">
        <a-input  v-model="item.colTitle"></a-input>
      </span>
      <span class="usable">
        <a-switch checked-children="可用" un-checked-children="不可用" :checked="item.colEnabled" />
        <!-- <a-switch v-else checked-children="可用" un-checked-children="不可用" v-model="item.colEnabled" @change="editUsable(item)"  /> -->
      </span>
      <span class="usable" style="width:200px">{{item.colTitleRef}}</span>
    </div>
    <div class="act-con">
      <a-button type="primary" @click=" edit = true " v-if="!edit">编辑</a-button>
      <a-button class="dis" @click="edit = false" v-if="edit">取消</a-button>
      <a-button type="primary" @click="saveTemplate" v-if="edit">保存</a-button>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      keyList:{},
      edit:false,
      scoreTitleRowIdx:'',
      scoreExamDayUseFirstCell:''
    }
  },
  mounted(){
    this.getTemplate()
  },
  methods:{
    editUsable(item){
      console.log('执行')
      item.colEnabled = !item.colEnabled
      this.$set(item,'colEnabled',!item.colEnabled)
    },
    getTemplate(){
      this.$post(this.$url.成绩导入模板,{
        examSpotId:this.$store.state.curSpotId
      }).then(data => {
        data.data.list.forEach(elem => {
          elem.colEnabled = elem.colEnabled?true:false
        });
        this.keyList = data.data.list
        this.scoreTitleRowIdx = data.data.scoreTitleRowIdx
        this.scoreExamDayUseFirstCell = data.data.scoreExamDayUseFirstCell
      }).catch(() => {
          
      });
    },
    saveTemplate(){
      
      this.$post(this.$url.成绩导入模板设置,{
        list:this.keyList,
        examSpotId:this.$store.state.curSpotId,
        scoreTitleRowIdx:this.scoreTitleRowIdx,
        scoreExamDayUseFirstCell:this.scoreExamDayUseFirstCell
      }).then(data => {
        this.getTemplate()
        this.edit = false
      }).catch(() => {
          
      });
    }
  }
}
</script>
<style scoped>
.line-num{
  border-top:solid 1px rgba(0,0,0,0.05);
  border-bottom:solid 1px rgba(0,0,0,0.05);
  padding:20px 0;
  margin-bottom:20px;
}
.template-con{
  background:#fff;
  padding:20px;
  box-sizing: border-box;
}
.act-con{
  width:550px;
  text-align:right;
  border-top:solid 1px rgba(0,0,0,0.05);
  padding-top:15px;
}
.info-title,.usable{
  width:200px;
  display:inline-block;
  padding:0 20px;
}
.info-title{
  width:90px;
}
.flex{
  margin:15px 0;
}

</style>