<template>
  <div class="info-containe">
    <import-score-template />
  </div>
</template>
<script>
import ImportScoreTemplate from '../components/ImportScoreTemplate'
export default {
  components:{
    ImportScoreTemplate
  },
  data(){
    return{
      
    }
  },
  mounted(){
  },
  methods:{
    
  },
  
}
</script>
<style scoped>
.info-containe{
  width:100%;
  max-width:600px;
}
.info-title,span{
  line-height:30px;
  margin-right:10px;
}
.flex{
  margin:15px 0;
}
.act-con{
  text-align:right
}
</style>